import React from 'react'
import { Link } from 'gatsby'

export default function LinkButton ({ label, url, openInNewWindow, className }) {
  if (url.slice(0, 1) === '#') {

    const onClick = () => {
      const anchor = url.slice(1)
      const el = document.getElementById(anchor)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (
      <a className={`Button ${className || 'Button--info'}`} onClick={onClick}>
        {label}
      </a>
    )
  }

  const isInternalLink = !openInNewWindow && url.slice(0, 1) === '/'

  if (isInternalLink) {
    return (
      <Link
        className={`Button ${className || 'Button--info'}`}
        to={url}
      >
        {label}
      </Link>
    )
  }

  return (
    <a
      className={`Button ${className || 'Button--info'}`}
      href={url}
      target={openInNewWindow ? '_blank' : undefined}
      rel={openInNewWindow ? 'noopener noreferrer' : undefined}
    >
      {label}
    </a>
  )
}
