import React from 'react'

export default function Filters ({ title, categories, filters, toggleFilter }) {
  return (
    <section className='Filters'>
      {title && (
        <h2>{title}</h2>
      )}
      <ul className='Filter-List'>
        {categories.map(category => {
          const { name, slug } = category
          return (
            <li key={slug} className={slug}>
              <button
                className={`Button Button--filter ${filters.includes(slug) ? 'is-toggled' : ''}`}
                onClick={() => toggleFilter(slug)}
              >
                {name}
              </button>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
