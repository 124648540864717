import React, { useRef } from 'react'
import useLazyImg from '../hooks/use-lazy-img'
import { getYouTubeId } from 'usfl/media/youtube'

function LazyYoutubeEmbed ({ src, style }) {
  const ref = useRef()
  useLazyImg(ref)

  return (
    <iframe
      ref={ref}
      style={style}
      width='560'
      height='315'
      data-src={src}
      frameBorder='0'
      scrolling='no'
      allowFullScreen
    >
    </iframe>
  )
}

export default function YoutubeEmbed ({ video, lazy, style }) {
  const id = getYouTubeId(video || '') || video

  if (!id) {
    return null
  }

  const src = `https://www.youtube.com/embed/${id}?enablejsapi=1`

  if (lazy) {
    return (
      <LazyYoutubeEmbed src={src} style={style} />
    )
  }

  return (
    <iframe
      style={style}
      width='560'
      height='315'
      src={src}
      frameBorder='0'
      scrolling='no'
      allowFullScreen
    >
    </iframe>
  )
}
