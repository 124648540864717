import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import LessonPlans from '../components/lesson-plans'
// import Json from '../components/json'

export default function PageMaterials ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { hero, lessonPlans } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero} />
        {(lessonPlans && lessonPlans.sections) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <LessonPlans data={lessonPlans} />
          </section>
        )}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageMaterialsQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...LessonPlansFragment
      }
    }
  }
`
