import React from 'react'
import YoutubeEmbed from './youtube-embed'

export default function YoutubePlayer ({ youtubeId, open, onClose }) {
  return (
    <article className={`Video u-absCenterChild ${open && youtubeId ? 'is-visible' : 'is-hidden'}`}>
      <div className='Video-inner u-padH'>
        <div className='Video-player'>
          <div className='Video-close'>
            <button className='Button Button--close' onClick={onClose}>
              close
            </button>
          </div>
          {youtubeId && (
            <YoutubeEmbed
              video={open ? youtubeId : null}
            />
          )}
        </div>
      </div>
    </article>
  )
}
