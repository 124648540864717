import React, { useState } from 'react'
import LazyImg from './lazy-img'
import YoutubePlayer from './youtube-player'
import LinkButton from './link-button'

export default function Hero ({ data, linkButtonClass, children }) {
  const {
    title,
    description,
    backgroundImage,
    heroYoutubeVideoButtonLabel,
    heroYoutubeVideoId,
    linkButtonLabel,
    linkButtonUrl
  } = data

  const [videoOpen, setVideoOpen] = useState(false)

  const onOpenVideo = () => setVideoOpen(true)

  const onCloseVideo = () => setVideoOpen(false)

  return (
    <>
      <article className='Hero u-overlay u-marginBottom' data-template='hero'>
        {/* Badge */}
        <div className='Hero-background u-mediaContainer'>
          {backgroundImage && (
            <LazyImg
              src={backgroundImage.mediaItemUrl}
              alt={title}
            />
          )}
        </div>
        <div className='Hero-inner'>
          <h1>{title}</h1>
          {description && (
            <p>{description}</p>
          )}
          <div className='Hero-buttons'>
            {heroYoutubeVideoId && (
              <button className='Button Button--video Button--outline' onClick={onOpenVideo}>
                {heroYoutubeVideoButtonLabel}
              </button>
            )}
            {linkButtonLabel && linkButtonUrl && (
              <LinkButton
                label={linkButtonLabel}
                url={linkButtonUrl}
                className={linkButtonClass}
              />
            )}
          </div>
        </div>
        {children}
      </article>
      {heroYoutubeVideoId && (
        <YoutubePlayer
          open={videoOpen}
          onClose={onCloseVideo}
          youtubeId={heroYoutubeVideoId}
        />
      )}
    </>
  )
}
