import React, { useState } from 'react'
import LazyImg from './lazy-img'
import Slider from 'react-slick'
import useIsMobile from '../hooks/use-is-mobile'
import Filters from './filters'
import Html from './html'

function Wrapper ({ children }) {
  const mobile = useIsMobile()

  if (mobile) {
    return (
      <div className={`Cards`}>
        <Slider
          arrows={false}
          dots
          dotsClass='Carousel-nav'
          autoplay
        >
          {children}
        </Slider>
      </div>
    )
  }

  return (
    <section className={`u-sectionPaddedX`}>
      <div className={`Cards`}>
        {children}
      </div>
    </section>
  )
}

export default function LessonPlans ({ data }) {
  const { sections } = data

  const [filters, setFilters] = useState([])

  const categories = sections.map(item => ({
    name: item.title,
    // slug: formatSlug(item.title)
    slug: item.title
  }))

  const toggleFilter = slug => {
    if (filters.includes(slug)) {
      setFilters([])
    } else {
      setFilters([slug])
    }
  }

  let items = sections
  if (filters.length) {
    items = sections.filter(section => filters.includes(section.title))
  }

  return (
    <>
      {categories.length > 1 && (
        <Filters
          categories={categories}
          filters={filters}
          toggleFilter={toggleFilter}
        />
      )}
      {items.map(section => {
        return (
          <section key={section.title} className='u-sectionDivider'>
            <div className='u-sectionPadded'>
              <h2 className='u-centered u-max-md'>
                {section.title}
              </h2>
              {section.description && (
                <Html className='Page-content u-centered u-max-md'>
                  {section.description}
                </Html>
              )}
            </div>
            <div className='u-centered'>
              <Wrapper>
                {section.items.map(item => {
                  const href = item.url || (item.file && item.file.mediaItemUrl)
                  const download = (item.file && item.file.mediaItemUrl) || undefined
                  return (
                    <section key={item.description} className={`Card Product-card Card--three`}>
                      <div className={`Product Product--secondary Product--tease u-imgHover`}>
                        <a
                          href={href}
                          download={download}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className={`Card-image u-mediaContainer`}>
                            <LazyImg src={item.image.mediaItemUrl} alt={item.title} />
                          </div>
                          <div className='Product-info'>
                            <div>
                              <h3>{item.title}</h3>
                              <a
                                className='Button--underline'
                                href={href}
                                download={download}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {item.label}
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </section>
                  )
                })}
              </Wrapper>
            </div>
          </section>
        )
      })}
    </>
  )
}
